@import '../../../css/global';

.wrap {
  padding: 0 30px 50px;

  :global(.cq-800) & {
    padding: 0 40px 50px;
  }
}

.group {
  font-size: 14px;

  + .group {
    margin-top: 30px;
  }
}

.title {
  margin: 0 0 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-opacity-05);
}

.downloads {
  margin-top: 30px;

  a,
  .note {
    display: inline-block;
    padding: 6px 10px 6px 0;
    border-radius: 4px;
    color: var(--color-primary);
  }

  a {
    &:hover {
      text-decoration: underline;
    }

    @include focus-self-outline;
  }

  .note {
    color: var(--color-opacity-05);
  }
}

.downloadsIcon {
  display: inline-block;
  position: relative;
  top: -2px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
