// ======================================================================
// WRAPPER
// ======================================================================

.wrap {
  display: grid;
  height: 100vh;
  height: 100svh;

  &--home {
    background-image: url(../../../public/images/bg1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(ellipse at center, rgba(17, 17, 17, 0.5) 20%, rgba(17, 17, 17, 0) 100%);
    }

    &:after {
      content: '';
      position: absolute;
      top: 75%;
      left: 0;
      width: 100%;
      height: 25%;
      background: linear-gradient(rgba(17, 17, 17, 0), rgba(17, 17, 17, 1));
    }
  }
}

.layout {
  display: grid;
  grid-template-areas:
    'sidebar content rightbar'
    'controls controls controls';
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr auto;
  height: 100vh;
  height: 100svh;
}

.layout-sidebar {
  overflow: auto;
  grid-area: sidebar;
  width: 200px;
  background: var(--color-background);

  @media (min-width: 1024px) {
    width: 220px;
  }

  html.electron.electron-platform-win & {
    width: 207px;

    @media (min-width: 1024px) {
      width: 227px;
    }
  }
}

.layout-content {
  overflow: auto;
  grid-area: content;
  background: var(--color-background);
  // container-type: inline-size;
}

.layout-controls {
  grid-area: controls;
  background: var(--color-panel-background);
}

.layout-rightbar {
  overflow: auto;
  grid-area: rightbar;
  width: 280px;
  background: var(--color-panel-background);

  @media (min-width: 1024px) {
    width: 320px;
  }

  html.electron.electron-platform-win & {
    &:after {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      width: 279px;
      height: 30px;
      background: var(--color-panel-background);

      @media (min-width: 1024px) {
        width: 319px;
      }
    }
  }
}

.loading {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  height: 100svh;

  background: var(--color-background);
  background-image: url(../../images/loading-white.png);
  background-size: 30px auto;
  background-position: center;
  background-repeat: no-repeat;
}

.wrap-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: 100svh;
  padding: 25px 0;
  padding-bottom: calc(25px + 3vh);

  > div {
    width: 100%;
  }
}
