@import '../../../css/global';

.wrap {
  padding: 80px 15px 50px;
  z-index: 2;
  text-align: center;

  @media (min-width: 768px) {
    padding: 100px 15px 50px;
  }
  @media (min-width: 1024px) {
    padding: 150px 20px 90px;

    @media (max-height: 900px) {
      padding-top: 120px;
    }
    @media (max-height: 800px) {
      padding-top: 100px;
    }
  }
}

//
// INTRO
//

.intro {
  max-width: 480px;
  margin: 0 auto;
}

.badge {
  display: inline-block;
  min-width: 140px;
  padding: 6px 15px;
  background: rgba(44, 44, 44, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 99px;

  font-size: 13px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #767676;

  @media (min-width: 1024px) {
    min-width: 180px;
    padding: 8px 20px;
    font-size: 14px;
  }
}

.h1 {
  font-size: 48px;
  line-height: 1;
  font-weight: bold;

  @media (min-width: 390px) {
    font-size: 56px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
  }
}

.h2 {
  font-size: 30px;
  line-height: 1;
  font-weight: bold;

  // @media (min-width: 390px) {
  //   font-size: 30px;
  // }
  // @media (min-width: 1024px) {
  //   font-size: 30px;
  // }
}

.hr {
  width: 150px;
  height: 2px;
  margin: 0 auto;
  background: #fff;
  opacity: 0.2;
}

.body {
  max-width: 90%;
  margin: 0 auto;
  font-size: 17px;
  text-wrap: balance;

  @media (min-width: 1024px) {
    max-width: none;
    font-size: 19px;
  }
}

//
// IMAGE
//

.image {
  overflow: hidden;
  width: 100%;
  max-width: 460px;
  border: 1px solid #333;
  border-radius: 6px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);

  @media (min-width: 768px) {
    width: 80vw;
    max-width: none;
    border-radius: 8px;
  }
  @media (min-width: 1024px) {
    max-width: 1200px;
    border-radius: 12px;
  }
}

.margin {
  margin: 100px auto;

  @media (min-width: 768px) {
    margin: 115px auto;
  }
  @media (min-width: 1024px) {
    margin: 135px auto;

    @media (max-height: 900px) {
      margin: 120px auto;
    }
    @media (max-height: 800px) {
      margin: 110px auto;
    }
  }
}

//
// DOWNLOADS
//

.downloadsFlex {
  display: flex;
  justify-content: center;

  > div {
    text-align: left;
  }

  a {
    display: inline-block;
    padding: 5px;
    border-radius: 4px;
    color: $color-primary;

    &:hover {
      text-decoration: underline;
    }

    @include focus-self-outline;
  }
}

.downloadsIcon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.note {
  display: inline-block;
  padding: 5px;
  color: var(--color-opacity-05);
}

//
// SOCIAL
//

.social {
}

.icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.icon {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  @include focus-self-outline;
}

.kofi {
  display: block;
  width: 240px;
  height: 38px;
  margin: 40px auto 0;
  background: #0067fc;
  background-image: url(../../../images/kofi.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;

  font-size: 0;
  line-height: 0;
  text-indent: -9999px;

  @media (min-width: 390px) {
    width: 280px;
    height: 44px;
  }

  @include focus-self-outline;
}

//
// FOOTER
//

.border,
.borderSmall {
  max-width: 1280px;
  height: 1px;
  margin: 100px auto 50px;
  background: rgba(255, 255, 255, 0.15);

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.15) 20%,
    rgba(255, 255, 255, 0.15) 80%,
    rgba(255, 255, 255, 0) 100%
  );

  @media (min-width: 768px) {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.15) 40%,
      rgba(255, 255, 255, 0.15) 60%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media (min-width: 1024px) {
    margin: 120px auto 90px;
  }
}

.borderSmall {
  max-width: 200px;
  margin: 20px auto;
}

.legal {
  font-size: 12px;
  color: #555;
}
