.icon {
  display: inline-block;
  position: relative;
  vertical-align: top;
  // overflow: hidden;
  overflow: visible;
  width: 1em;
  height: 1em;
  margin: 0;

  fill: currentColor;

  font-size: 1em;
  line-height: 1em;

  svg {
    display: block;
    // vertical-align: text-bottom;
    vertical-align: baseline !important;
    overflow: visible;
    user-select: none;
    width: 1em;
    height: 1em;
  }

  path {
    fill: currentColor;
  }

  &Cover {
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &Stroke {
    fill: none;
    stroke: currentColor;

    path {
      fill: none;
      stroke: currentColor;
    }
  }

  &StrokeWidth10 {
    path {
      stroke-width: 1;
    }
  }
  &StrokeWidth11 {
    path {
      stroke-width: 1.1;
    }
  }
  &StrokeWidth12 {
    path {
      stroke-width: 1.2;
    }
  }
  &StrokeWidth13 {
    path {
      stroke-width: 1.3;
    }
  }
  &StrokeWidth14 {
    path {
      stroke-width: 1.4;
    }
  }
  &StrokeWidth15 {
    path {
      stroke-width: 1.5;
    }
  }
  &StrokeWidth16 {
    path {
      stroke-width: 1.6;
    }
  }
  &StrokeWidth17 {
    path {
      stroke-width: 1.7;
    }
  }
  &StrokeWidth18 {
    path {
      stroke-width: 1.8;
    }
  }
  &StrokeWidth19 {
    path {
      stroke-width: 1.9;
    }
  }
  &StrokeWidth20 {
    path {
      stroke-width: 2;
    }
  }
}
