@import '../../../css/global';

.wrap {
  padding: 0 40px 40px;
}

.title {
  font-size: 48px;
  line-height: 1;
  font-weight: bold;

  // &.medium {
  //   font-size: 42px;
  // }
  // &.small {
  //   font-size: 36px;
  // }
}
