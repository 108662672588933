@import '../../../css/global';

.wrap {
  padding: 0 30px 50px;

  :global(.cq-800) & {
    padding: 0 40px 50px;
  }

  hr {
    margin: 36px 0;
    border: 0;
    border-top: 1px solid var(--color-opacity-015);
  }
}
