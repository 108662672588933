@import '../../../css/global';

.wrap {
  padding: 0 30px 50px;

  :global(.cq-800) & {
    padding: 0 40px 50px;
  }
}

.group {
  font-size: 15px;

  + .group {
    margin-top: 45px;
  }
}

.title {
  margin: 0 0 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-opacity-05);
}

.entry {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  padding: 10px 0 10px 1px;
  border-bottom: 1px solid var(--color-opacity-015);

  @include focus-self-outline;
}

.entryIconSmall,
.entryIconMed,
.entryIconLarge {
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
  opacity: 0.5;
  // background: rgba(255, 0, 0, 0.5);
}

.entryIconMed {
  width: 22px;
  height: 22px;
  margin: 0;
  // color: #f009;
}

.entryIconLarge {
  width: 26px;
  height: 26px;
  margin: -3px -2px -2px;
  // color: #f00;
}

.externalIcon {
  display: inline-block;
  position: relative;
  top: 3px;
  width: 14px;
  height: 14px;
  margin-left: 10px;
  opacity: 0.4;
}

.entryArrow {
  display: block;
  width: 14px;
  height: 14px;
  opacity: 0.6;
}

.kofi {
  display: block;
  width: 220px;
  height: 35px;
  background: #0067fc;
  background-image: url(../../../images/kofi.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;

  font-size: 0;
  line-height: 0;
  text-indent: -9999px;

  @include focus-self-outline;
}

.legal {
  margin: 35px 0 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-opacity-03);
}
