// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

// Thin
// Light
// Regular
// Medium
// Bold
// Black

// ==================================================
// GEOGRAPH
// ==================================================

@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebthin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebthinitalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographweblight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographweblightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebregularitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebmedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebmediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebbolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'GeographWeb';
  src: url('../../fonts/geograph/geographwebblackitalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
