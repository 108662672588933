@import '../../../css/global';

.loader {
  position: fixed;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 32px;
  min-height: 32px;
  background-image: url(../../../images/loading-grey.gif);
  background-image: url(../../../images/loading-grey.png);
  background-size: 32px 32px;
  background-position: 50% 45%;
  background-repeat: no-repeat;
  opacity: 0;
  animation: fade-in 1s 0.5s forwards;

  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}

.loaderOffline {
  display: flex;
  position: fixed;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 32px;
  min-height: 32px;
  padding-top: 15vh;

  opacity: 0;
  animation: fade-in 1s 0.5s forwards;
}

.icon {
  width: 32px;
  height: 32px;
  margin: 0 auto;
  opacity: var(--opacity-05);
}

.message {
  margin: 10px auto 0;
  opacity: var(--opacity-05);
  font-size: 15px;
  text-align: center;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.inline {
  position: absolute;
  background-color: transparent;
}

// .route {
//   position: absolute;
//   min-height: calc(var(--vh100) - 60px - env(safe-area-inset-top));
//   background-color: transparent;

//   :global(.wrap--logged-in.wrap--header) & {
//     background-position: 50% calc(50% - 5vh - 30px - env(safe-area-inset-top) / 2);
//   }

//   &:before {
//     content: '';
//     display: block;
//     position: fixed;
//     width: 100%;
//     height: 50px;
//     @include electron-drag;
//   }
// }
