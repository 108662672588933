@import '../../../css/global';

.wrap {
}

// BUTTON

.btn {
  display: block;
  appearance: none;
  width: 100%;
  max-width: 220px;
  padding: 17px 16px;
  margin: 0;
  background: $color-primary;
  border: 1px solid $color-opacity-02;
  border-radius: 10px;

  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: $color-background;

  @include focus-self-outline;

  @media (min-width: 1024px) {
    max-width: 260px;
  }

  &:disabled {
    cursor: default;
    cursor: not-allowed;
  }

  :global(.text-center) & {
    margin: 0 auto;
  }

  :global(html.logged-in) & {
    background: var(--color-primary);
    border: 1px solid var(--color-opacity-02);
    color: var(--color-background);
  }

  // loading / submitting

  &.loading {
    background-image: url(../../../images/loading-white.gif);
    background-image: url(../../../images/loading-white.png);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    color: rgba(0, 0, 0, 0) !important;
  }

  // centering

  :global(.u-center-all) & {
    margin-inline: auto;
  }
  :global(.u-center-mobile) & {
    @media (max-width: 1023px) {
      margin-inline: auto;
    }
  }
  :global(.u-center-tablet-desktop) & {
    @media (min-width: 768px) {
      margin-inline: auto;
    }
  }
  :global(.u-center-desktop) & {
    @media (min-width: 1024px) {
      margin-inline: auto;
    }
  }
}

.download,
.downloadMac {
  max-width: 180px;
  padding: 16px 10px;
  font-size: 14px;
  line-height: 1;

  .icon {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    margin: 0 7px -8px -1px;
    top: -2px;
  }
}

.downloadMac {
  .icon {
    width: 22px;
    height: 22px;
    margin: 0 7px -8px -3px;
    top: -5px;
  }
}
