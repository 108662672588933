@import '../../../css/global';

.wrap {
  width: 100%;
  padding: 0 30px 50px;

  &:last-child {
    padding-bottom: 0;
  }
}

.entry {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 12px 30px;
  margin: 0 auto 5px;
  background: transparent;
  border: 1px solid var(--color-opacity-01);
  border-radius: 8px;
  text-align: left;

  @include focus-self-outline;

  &:hover {
    background: var(--color-opacity-01);
    border: 1px solid var(--color-opacity-005);
  }
}

.icon {
  width: 28px;
  height: 28px;
  margin-right: 20px;
}

.title {
  font-size: 15px;
}

.url {
  margin: 2px 0 0;
  font-size: 12px;
  color: var(--color-opacity-05);
}
