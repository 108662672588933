@import '../../../css/global';

.wrap {
  position: relative;
  top: 0;

  &Inline {
    display: inline-block;
    top: 1px;
    margin-left: 6px;

    &:first-child {
      margin-left: 0;
    }
  }

  &Zero {
    opacity: 0.5;
  }
}

.stars {
  display: flex;
}

.star {
}

.star + .star {
  margin-left: 2px;
}

.editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.edit {
  display: block;
  flex: 1 1 auto;
  height: 100%;

  &:first-child {
    flex: 0.5 1 auto;
  }
}
