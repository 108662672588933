// ======================================================================
// CSS CUSTOM PROPS
// ======================================================================

:root {
  // --color-background: #111;
  // --color-text: #fff;
  // --color-primary: #f7277a;

  // --color-panel-background: #1e1e1e;
  // --color-hover: #2a2a2a;
  // --color-border: #2a2a2a;

  // --color-background: #37011b;
  // --color-panel-background: #460323;
  // --color-border: #57032a;
  // --color-text: #fff;
  // --color-primary: #00c0dd;

  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}
