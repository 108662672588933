@import '../../../css/global';

.wrap {
  display: grid;
  // overflow: hidden;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 20px 10px;
  padding: 0 20px 50px;

  :global(.cq-620) & {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  :global(.cq-800) & {
    padding: 0 30px 50px;
  }
  :global(.cq-860) & {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

.card {
  padding: 9px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 13px;

  @include focus-self-outline;

  &:hover {
    background: var(--color-opacity-01);
    border: 1px solid var(--color-opacity-005);
  }

  &Link {
    cursor: pointer;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.thumb {
  overflow: hidden;
  width: 100%;
  height: 0;
  padding: 0 0 60%;
  background: var(--color-opacity-01);
  border-radius: 6px;
  // outline: 1px solid var(--color-opacity-01);

  &.thumbSquare {
    padding: 0 0 100%;
  }
  &.thumbWithIcon {
    background: var(--color-primary);
    background: linear-gradient(to bottom, var(--color-primary) 0%, var(--color-background) 400%);
    color: var(--color-background);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 42px;
  height: 42px;
  transform: translate(-50%, -50%);
}

.controlButtonWrap {
  display: block;
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  right: 10px;
  opacity: 0;
  transform: translateY(10px);
  transition: all ease-in-out 0.25s;

  .card:hover &,
  .card.cardCurrent & {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
  }
}

.playButton,
.pauseButton {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 11px 9px 11px 13px;
  background: var(--color-primary);
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.35);
  transition: all ease-in-out 0.25s;
  color: var(--color-panel-background);
  cursor: pointer;

  .card:hover &:hover,
  .card.cardCurrent &:hover {
    transform: translateY(0) scale(1.1);
  }
}

.pauseButton {
  padding: 12px;
}

.body {
  padding: 10px 2px 2px;
  font-size: 11px;
}

.title {
  font-size: 12px;
  font-weight: 500;
}

.subtitle {
  display: block;
  color: var(--color-opacity-05);
}

.rating {
  margin-top: 6px;
  color: var(--color-opacity-03);
}
