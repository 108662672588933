@import '../../../css/global';

.wrap {
  padding: 0 40px 20px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}
