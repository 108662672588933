@import '../../../css/global';

.wrap {
  padding: 0 30px 50px;

  :global(.cq-800) & {
    padding: 0 40px 50px;
  }
}

.group {
  + .group {
    margin-top: 16px;
  }
}

.title {
  margin: 0 0 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-opacity-05);
}

.themes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.theme {
  display: block;
  position: relative;
  overflow: hidden;
  width: 36px;
  height: 36px;
  margin: 0 15px 15px 0;
  border-radius: 5px;
  outline: 1px solid var(--color-opacity-02);

  @include focus-self-outline;

  &Current {
    outline: 4px solid var(--color-opacity-04);
  }
}

.themeBackground {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.themeText {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-width: 0 0 26px 26px;
  border-style: solid;
}

.icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
}

.custom {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 15px 10px;
}

.customField {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 25px;
}

.customLabel {
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
}

.customInput {
  width: 60px;
  height: 30px;
  border-radius: 2px;

  @include focus-self-outline;
}

.button {
  display: inline-block;
  padding: 5px 25px;
  background: var(--color-opacity-02);
  border-radius: 50px;
  font-size: 14px;

  @include focus-self-outline;
}

.menu {
}

.menuEntry {
  @include focus-parent-outline;

  + .menuEntry {
    // margin-top: 4px;
    margin-top: 14px;
  }

  label {
    display: inline-flex;
    align-items: center;
    margin: 0 auto 0 0;
  }

  input[type='checkbox'] {
    margin: 0 10px 0 0;
  }
}

.label {
}

.description {
  display: block;
  font-size: 12px;
  color: var(--color-opacity-07);
}
